import { logoutApi} from "../api";
import React, {useEffect, useMemo, useState} from "react";
import sockets from "../utils/sockets";
import {Link} from "react-router-dom";

const TemplateHeader = () =>{
    const [headerInfo, setHeaderInfo] = useState<string>('');
    const onLogout = () =>{
        logoutApi().finally(()=>{
            window.location.href="/sign-in";
        });

    }
    const updateHeaderInfoListener = (data:string)=>{
        setHeaderInfo(data);
    }
    useEffect(()=>{
        sockets.getInstance().addListener('headerInfo',updateHeaderInfoListener);
        return ()=>{
            sockets.getInstance().removeListener('headerInfo',updateHeaderInfoListener);
        }
    })


    return(
        <header className="header col-auto">
            <div className="row g-0 align-items-center flex-nowrap">
                <div className="col-auto d-md-none">
                    <button className="toggle-menu">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </button>
                </div>
                <div className="col-auto d-none d-md-block">
                    <div className="welcome">Welcome <span className="name"></span></div>
                </div>

                <div className='col text-info text-center'>{headerInfo || ''}</div>
                <div className="col right-pan">
                    <a href={"#"} onClick={onLogout} className="logout text-white">Log Out</a>
                </div>
            </div>
        </header>
    )
}
export default TemplateHeader