import React, {Dispatch, useEffect} from "react";
import "bootstrap/scss/bootstrap.scss"
import "./template.scss"
import 'react-toastify/dist/ReactToastify.css';
//import "./preloader.scss"
import TemplateHeader from "./template-header";
import LeftMenu from "./left-menu";
import {Outlet} from "react-router";
import {getCountriesAPI, getServerListAPI, getTariffListAPI} from "../api";
import {ApiData} from "../api/helper";
import {useDispatch} from "react-redux";
import {loadCountries} from "../reducers/country-reducer";
import {loadTariffs} from "../reducers/tariff-reducer";
import {loadServers} from "../reducers/server-reducer";
function bootstrap(dispatch:Dispatch<any>){

    getCountriesAPI()
        .then((d:ApiData)=>!d.error?dispatch(loadCountries(d.data)):console.error(d.error))
        .catch(console.error);

    getTariffListAPI()
        .then((d:ApiData)=>!d.error?dispatch(loadTariffs(d.data)):console.error(d.error))
        .catch(console.error);
    getServerListAPI()
        .then((d:ApiData)=>!d.error?dispatch(loadServers(d.data)):console.error(d.error))
        .catch(console.error);
}

const CustomerBootstrapTemplate = () =>{
    const dispatch = useDispatch();
    useEffect(() => {
        bootstrap(dispatch);
    }, []);
     return(
            <div className="row g-0 flex-column vh-100">
                <TemplateHeader/>
                <div className="row g-0 align-items-stretch col main-row">
                    <LeftMenu/>
                    <div className="col main-col overflow-auto">
                        <main className="main">
                            <Outlet />
                        </main>
                    </div>
                </div>
            </div>
    )
}

export default CustomerBootstrapTemplate